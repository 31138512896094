<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="status">
            <el-select clearable class="small-input" v-model="searchForm.status" placeholder="状态">
              <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="errorType">
            <el-select clearable class="small-input" v-model="searchForm.errorType" placeholder="异常类型">
              <el-option
                  v-for="item in errorTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="position">
            <el-select clearable class="small-input" v-model="searchForm.position" placeholder="所在单位">
              <el-option
                  v-for="item in positionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="上报时间"
            prop="submitTime"
            width="200"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="异常类型"
            prop="typeName"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备名称"
            prop="name"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备id"
            prop="deviceId"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备imei"
            prop="imei"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="货道编号"
            prop="mainWayNo"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="订单号"
            prop="inventory"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="所在单位"
            prop="department"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="所在位置"
            prop="position"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="是否已处理"
            prop="disposeShow"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <el-switch style="margin-left: 10px" :disabled="scope.row.disposeShow" @change="changeStatus(scope.row)"
                       v-model="scope.row.disposeShow">
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
export default {
  name: "AutoAbnormal",
  mixins:[tableUse],
  data(){
    return {
      searchForm:{
        status:'',
        errorType:'',
        position:''
      },
      statusList:[
        {
          value:0,
          label:'待处理'
        }
      ],
      errorTypeList:[
        {
          value:0,
          label:'空货道'
        }
      ],
      positionList:[
        {
          value:0,
          label:'上海市第六人民医院'
        }
      ],
      tableData:[]
    }
  },
  methods:{
    reset(){
      this.searchForm = {
        status:'',
        errorType:'',
        position:''
      }
      this.search();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
